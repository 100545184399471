import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import Markdown from "react-markdown"
import Footer from "../components/footer"

const IndexPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout seo={data.strapiAboutpage.seo}>
      <div className="rounded-b-3xl py-8">
        <div className="flex flex-col gap-12">
          <div className="lg:py-12 inline-block lg:flex flex-row xl:px-16 px-6">
            <p className="text-small lg:text-demilarge font-bold lg:w-2/3 text-primary">
              {data.strapiAboutpage.hero.title}
            </p>
            <p className="text-base lg:text-semismall lg:w-1/3 text-primary">
              <Markdown
                children={data.strapiAboutpage.hero.description}
                escapeHtml={false}
              />
            </p>
          </div>
          <div
            className="rounded-b-2xl lg:rounded-b-3xl"
            style={{ boxShadow: "0px 0px 15px 3px #0000001A" }}
          >
            <div className="py-8 pb-16">
              <div className=" xl:px-16 px-6 flex flex-col gap-24">
                <div className="flex flex-col lg:grid grid-cols-3 lg:gap-16 lg:pr-40">
                  <div>
                    <GatsbyImage
                      className="rounded-lg "
                      image={
                        data.strapiAboutpage.team[0].image.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={`Hero image`}
                    />
                  </div>
                  <div className="col-span-2 py-2">
                    <p className="font-bold lg:text-small text-base text-primary">
                      {data.strapiAboutpage.team[0].name}
                    </p>
                    <p className="lg:text-semismall text-base text-primary">
                      {data.strapiAboutpage.team[0].position}
                    </p>
                    <div className="flex flex-col gap-4 mt-6 lg:text-semismall text-base">
                      <Markdown
                        children={data.strapiAboutpage.team[0].description}
                        escapeHtml={false}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col lg:grid grid-cols-3 lg:gap-16 lg:pl-40">
                  <div className="col-span-2 py-2 order-last lg:order-first">
                    <p className="font-bold lg:text-small text-base text-primary">
                      {data.strapiAboutpage.team[1].name}
                    </p>
                    <p className="lg:text-semismall text-base text-primary">
                      {data.strapiAboutpage.team[1].position}
                    </p>
                    <div className="flex flex-col gap-4 mt-6 lg:text-semismall text-base">
                      <Markdown
                        children={data.strapiAboutpage.team[1].description}
                        escapeHtml={false}
                      />
                    </div>
                  </div>
                  <div>
                    <GatsbyImage
                      className="rounded-lg "
                      image={
                        data.strapiAboutpage.team[1].image.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={`Hero image`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiAboutpage {
      hero {
        title
        description
      }
      team {
        id
        name
        description
        position
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 493, height: 817)
            }
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default IndexPage
